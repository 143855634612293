ul li {
    list-style: disc;
    margin-left: var(--space-2);
}

html {
    /* set background color for iOS pull up */
    background-color: var(--black-300);
    font-family: Ubuntu;
}

html {
    height: 100%;
}

* {
    line-height: 1.5;
}

body {
    /* set actual background color */
    background-color: #FFF;
    min-height: 100%;
}

main {
    margin: 0 var(--space-2);
    max-width: var(--max-width);
}

p {
    margin: 8px;
}

strong {
    font-weight: 500;
}

.top-nav {
    display: flex;
    align-items: center;
    margin-bottom: var(--space-3);
    background-color: var(--black-300);
    color: #FFF;
    padding: var(--space-2);
}

.top-nav h1 {
    font-size: var(--mobile-heading-3);
    font-weight: bold;
}

.top-nav h1 a:link,
.top-nav h1 a:visited
{
    color: #fff;
    text-decoration: none;
}

.menu-icon {
    font-size: var(--mobile-heading-2);
    margin-right: var(--space-2);
}

.top-nav h1 {
    margin: 0;
}

h1, h2, h3, h4, h5 {
    font-weight: 500;
    text-align: center;
    margin-bottom: var(--space-3);
}

h1 { font-size: var(--mobile-heading-1); }
h2 { font-size: var(--mobile-heading-2); }
h3 { font-size: var(--mobile-heading-3); }
h4 { font-size: var(--mobile-heading-4); }

.video-container,
.video-placeholder {
    border-radius: 8px;
    overflow: hidden;
}

.video-placeholder {
    width: auto;
    aspect-ratio: 16 / 9;
    background-color: var(--black-100);
    display: flex;
    align-items: center;
}

.video-placeholder.error {
    background-color: var(--red-100)
}

.video-placeholder.error h2 {
    width: 100%;
    color: var(--red-300)
}

.box {
    padding: var(--space-3);
    margin-bottom: var(--space-3);
    border-radius: 8px;
}

.light.box {
    border: 2px var(--blue-200) solid;
}

.box li,
.box p
{
    font-weight: 300;
    font-size: var(--mobile-paragraph);
    margin-left: 20px;
}

.box li {
    margin-bottom: var(--mobile-paragraph);
}

.box h2,
.box h3,
.box h4 {
    text-align: center;
}

h3.checked {
    text-align: left;
    margin-bottom: var(--space-2);
    margin-left: 24px;
}

h3.checked:before {
    margin-right: 8px;
    content: '\f14a';
    font-weight: 900;
    font-family: "Font Awesome 6 Free";
    color: var(--green-300);
    margin-left: -28px;
}


button,
.button {
    border-style: none;
    display: block;
    text-align: center;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 14pt;
    line-height: 34pt;
    height: 48px;
    border-radius: 8px;
    text-decoration: none;
}

button.primary,
.button.primary {
    background-color: var(--blue-200);
    color: #FFF;
}

button.secondary,
.button.secondary {
    background-color: #FFF;
    color: var(--blue-200);
    border: 2px var(--blue-200) solid;
}

.price-caption {
    color: var(--black-200);
    font-style: oblique;
    font-weight: 300;
    font-size: smaller;
    margin-bottom: var(--space-3)
}

.slashed-price {
    text-decoration: line-through;
    color: var(--black-200);
    font-size: smaller;
    font-weight: 300;
}

.box .price {
    font-size: var(--mobile-heading-2);
    color: var(--green-300);
    font-weight: 500;
    text-align: center;
}

.box .price:before {
    content: "$";
    font-size: var(--mobile-paragraph);
    color: #000;
    vertical-align: top;
    font-weight: normal;
}

.box .price:after {
    content: "/mo";
    font-size: var(--mobile-paragraph);
    color: var(--black-100);
    font-weight: normal;
}

.buy.box h2 {
    margin-bottom: 0;
}

.buy.box h3,
.buy.box p {
    margin-bottom: 16px;
}

footer p {
    text-align: center;
    font-size: var(--mobile-tiny);
}

footer {
    padding-bottom: var(--space-1);
}

.guarantee.emphasis {
    font-weight: 300;
    color: var(--red-200);
    text-align: center;
}

.guarantee.emphasis strong {
    font-size: var(--mobile-heading-3);
}

.video-container iframe {
    width: 100%;
    height: 100%;
    aspect-ratio: 16 / 9;
}

.dark.box {
    background-color: var(--black-300);
    color: #FFF;
}

.dark.box p {
    text-align: center
}

.fine-print {
    font-size: var(--mobile-micro)
}

ul.checked li {
    list-style: none;
    margin-left: 41px; /* 25px + 16px */
}

ul.checked li:before {
    content: '\f00c';
    font-weight: 900;
    font-family: "Font Awesome 6 Free";
    margin-right: var(--space-1);
    margin-left: -25px;
}

ul.checked li.no:before {
    content: '\f00d'
}

.capitalized {
    text-transform: uppercase;
}

.box button {
    width: 100%;
}

.box input {
    font-family: Ubuntu, sans-serif;
    font-size: 16px;
    border-style: none;
    border-radius: 8px;
    height: 48px;
    text-align: center;
    margin-bottom: var(--space-1);
    width: 100%;
}

* {
    box-sizing: border-box;
}

.dark a {
    color: #FFF;
}

.centered {
    text-align: center;
}

.layout-centered {
    margin: 0 auto;
}

.buy.box {
    position: relative;
}

.buy .badge {
    position: absolute;
    top: 30px;
    left: 0;
    background: var(--black-100);
    padding: 10px;
    border-radius: 0 8px 8px 0;
    font-size: 11px;
}

.buy .best-offer {
    background: var(--green-300);
    color: var(--green-100);
}

.sale-sticker {
    display: flex;
    flex-flow: column nowrap;
    background: var(--green-300);
    color: var(--green-100);
    border-radius: 8px;
    padding: 10px;
    width: 50%;
    margin-right: var(--space-1);
    margin-bottom: var(--space-3);
}

.sale-price {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
}

.sale-amount {
    font-size: 36px;
    font-weight: 500;
}

.price-block {
    /* line up buttons when side by side */
    min-height: 122px;
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
}

/**
 * Grid
 */

/* Mobile */
.desktop.hidden { display: block; }
.mobile.hidden { display: none; }
[class*="col-"] {
    width: 100%;
}

@media only screen and (min-device-width: 375px) {
    /* Small tweaks for large-enough mobile devices */
    .buy .badge {
        top: 28px;
        font-size: 13px;
    }

    .sale-sticker {
        padding: 10px 20px;
        max-width: 140px;
    }
}

@media only screen and (min-device-width: 768px) {
    /* Desktop */
    .flex {
        display: flex;
        gap: 16px;
    }

    /* For desktop: */
    .col-1 {width: 8.33%;}
    .col-2 {width: 16.66%;}
    .col-3 {width: 25%;}
    .col-4 {width: 33.33%;}
    .col-5 {width: 41.66%;}
    .col-6 {width: 50%;}
    .col-7 {width: 58.33%;}
    .col-8 {width: 66.66%;}
    .col-9 {width: 75%;}
    .col-10 {width: 83.33%;}
    .col-11 {width: 91.66%;}

    .col-12 {
        width: 100%;
    }

    .desktop.hidden { display: none; }
    .mobile.hidden { display: block; }

}

@media only screen and (min-device-width: 800px) {
    /* Slightly larger than 768 to add proper margins without affecting the margins on 768 */
    main {
        margin: 0 auto;
    }
}

.padding-0 { padding: 0; }